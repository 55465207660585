// Foundation.Reveal.defaults.deepLink = true;

$(document).foundation();

let vide_options = {
    // mp4       : '/files/videos/header_video.mp4',
    //mp4       : 'http://csvcus.homeaway.com/rsrcs/stab-cms-resources/0.2.8/videos/homepage/campaign_jan_2016.mp4',
    //mp4       : 'https://a0.muscache.com/airbnb/static/P1-background-vid-compressed-2.mp4',
    poster: '/files/images/bg.png',
    posterType: 'png'
};

if (Foundation.MediaQuery.atLeast('medium')) {
    vide_options.mp4 = '/files/videos/header_video.mp4';
}

$('#background_video').vide(
    vide_options
);

// AOS.init();

lightbox.option(
    {
        'albumLabel': '%1 / %2',
    }
);

jQuery(document).ready(function ($) {
    /*$('#partners_slider').slick(
     {
     dots  : true,
     arrows: false
     }
     );

     let $youtubeSlider = $('#youtube_slider');
     $youtubeSlider.slick(
     {
     lazyLoad: 'ondemand',
     dots    : true
     }
     );


     $youtubeSlider.find('a')
     .on('mousedown', function (e) {
     $(this).data('moving', false);
     })
     .on('mousemove', function (e) {
     $(this).data('moving', true);
     })
     .on('mouseup', function (e) {
     if (!$(this).data('moving')) {
     var $that = $(this);
     e.preventDefault();
     $.post($that.data('href'), null, function (data) {
     $('#video_modal').html(data).foundation('open');
     });
     }
     });*/

    $('[data-ajax-load]').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let href = $this.attr('href');

        $.post(href, null, function (data) {
            if ($this.hasClass('modalFull')) {
                $('#universalFullModal').html(data).foundation('open');
            } else if ($this.hasClass('modalLarge')) {
                $('#universalLargeModal').html(data).foundation('open');
            } else {
                $('#universalModal').html(data).foundation('open');
            }
        });
    });

    $('a[href^=#]').on('click', function () {
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 1500);
    });

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > '500') {
            console.log('vis');
            $('#takeMeUp').css('opacity', '.8');
        } else {
            console.log('invis');
            $('#takeMeUp').css('opacity', '0');
        }

    });
});